@import 'node_modules/reset-css/sass/reset';
@import 'node_modules/jquery-modal/jquery.modal';

@import './helpers/variables';
@import './helpers/mixins';
@import './helpers/typography';
@import './helpers/spacing';

* {
    min-width: 0;
    min-height: 0;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
	height: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
	font-family: $roboto-light;
    color: $base;
	min-width: 320px;
	min-height: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	background: $white;
}

.content {
    position: relative;
    z-index: 1;
    flex: 1 0 auto;
    width: calc(100% - 4rem);
    max-width: 94rem;
    margin: 7rem 0 0;

    @include below($tablet) {
        margin: 1rem 0 0;
    }

    & p {
        font-size: 1.5rem;
        line-height: 1.45;
        margin: 1.75rem 0;

        @include below($tablet) {
            font-size: .87rem;
            line-height: 1.6;
        }

        &.blockquote {
            border-left: .5rem solid $gray;
            padding: 1rem;

            @include below($tablet) {
                border-width: .35rem;
                padding: .5rem;
            }

        }

        & b {
            font-family: $roboto-regular;
        }

    }

}

.visually-hidden {
    position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
}

@import '../_modules/form/form';
@import '../_modules/button/button';
@import '../_modules/preloader/preloader';
@import '../_modules/loader/loader';
@import '../_modules/modal/modal';
@import '../_modules/link/link';

@import '../_modules/header/header';
@import '../_modules/nav/nav';
@import '../_modules/footer/footer';

@import '../_modules/main/main';
@import '../_modules/contacts/contacts';
