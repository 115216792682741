.contacts {

    &-phone-link {
        @include transition();
        color: $base;
        text-decoration: none;

        @include below($tablet) {
            border-bottom: 2px solid $blue;
            display: inline-block;
            line-height: 1;
        }

        &:hover {
            color: $blue;
        }

    }

    &-email-link {
        @include transition();
        border-bottom: 2px solid $blue;
        display: inline-block;
        color: $base;
        text-decoration: none;
        line-height: 1;

        &:hover {
            color: $blue;
        }

    }

    &-navigator {

        &-container {
            display: none;

            @include below($tablet) {
                display: block;
            }

        }

        &-link {
            position: relative;
            display: inline-block;
            border-bottom: 2px solid $blue;
            line-height: 1;
            color: $base;
            text-decoration: none;
            margin-left: 1.25rem;

            &:before {
                @include size(14.5px, 14.5px);
                content: '';
                position: absolute;
                left: -1.25rem;
                background: transparent url('/images/base/wheel.svg') no-repeat 50% 50%;
                background-size: 100%;
            }

        }

    }

    &-map {
        margin: 2rem 0;
    }

}
