.main {
    position: relative;
    z-index: 1;
    flex: 1 0 auto;
    width: calc(100% - 4rem);
    max-width: 94rem;
    margin-top: 3.5rem;

    @include below($tablet) {
        margin-top: 1rem;
    }

    &__cover {
        position: relative;
        margin-top: 2rem;

        &-title {
            transition: all 1s ease;
            position: relative;
            z-index: 1;
            display: inline-block;
            background-color: $white;
            text-transform: uppercase;
            font-family: $roboto-regular;
            color: $base;
            font-size: 35px;
            padding: .85rem 1.5rem .7rem;
            border-radius: 3px;
            transform-style: preserve-3d;
            margin-bottom: 3rem;

            @include below($tablet) {
                font-size: 24px;
                margin-bottom: 4.5rem;
            }

            &.init {
                color: $white;
            }

            &:before {
                @include size(100%);
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: $black;
                border-radius: 3px;
                transform: rotate(5deg) scale(.95) translateZ(-1px);
                opacity: .2;
            }

            &-sub {
                transition: all 1s ease;
                position: absolute;
                color: $white;
                font-size: 22px;
                text-transform: none;
                font-family: $roboto-thin;
                white-space: nowrap;
                background-color: rgba(255, 255, 255, .2);
                padding: .2rem .75rem;
                border-radius: 2px;
                opacity: 1;
                visibility: visible;

                @include below($tablet) {
                    left: 0;
                    top: 100%;
                    font-size: 18px;
                }

                &.init {
                    opacity: 0;
                    visibility: hidden;
                }

                &--first, &--second {
                    margin-left: 2.5rem;

                    @include below($tablet) {
                        margin-left: 0;
                    }

                }

                &--first {
                    top: 0;

                    @include below($tablet) {
                        top: calc(100% + .15rem);
                    }

                }

                &--second {
                    bottom: 0;

                    @include below($tablet) {
                        bottom: auto;
                        top: calc(100% + 1.8rem);
                    }

                }

            }

        }

        &-intro {
            transition: all 2s ease;
            position: relative;
            z-index: 1;
            color: $white;
            font-size: 24px;
            line-height: 34px;
            max-width: 45%;
            margin-bottom: 2rem;
            opacity: 1;
            visibility: visible;

            @include below($big-desktop) {
                max-width: 50%;
            }

            @include below($tablet) {
                max-width: 100%;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 1rem;
            }

            &.init {
                opacity: 0;
                visibility: hidden;
            }

        }

        &-rolls {

            &-wrapper {

                position: absolute;
                top: -10rem;
                right: 0;
                width: 60%;
                height: 63rem;
                overflow: hidden;

                @include below($desktop) {
                    top: -5rem;
                    height: 45rem;
                }

                @include below($tablet) {
                    top: 7rem;
                    right: -2rem;
                    height: 32rem;
                }

            }

            &-film {
                transition: all 2s ease;
                position: absolute;
                z-index: 1;
                top: 10rem;
                right: 0;
                width: 738px;
                height: 540px;

                @include below($large-desktop) {
                    top: 12rem;
                    width: 500px;
                    height: 366px;
                }

                @include below($desktop) {
                    top: 15rem;
                    width: 350px;
                    height: 256px;
                }

                @include below($tablet) {
                    right: auto;
                    left: 1rem;
                    top: 4.5rem;
                    width: 300px;
                    height: 219px;
                }

                &.init {
                    top: 0;
                }

            }

            &-shadow {
                transition: all 2s ease;
                position: absolute;
                top: calc(175px + 10rem);
                right: 0;
                width: 808px;
                height: 503px;
                opacity: 1;
                visibility: visible;

                @include below($large-desktop) {
                    width: 540px;
                    height: 336px;
                }

                @include below($desktop) {
                    width: 370px;
                    height: 231px;
                }

                @include below($tablet) {
                    right: auto;
                    left: 0;
                    top: 10rem;
                    width: 300px;
                    height: 187px;
                }

                &.init {
                    top: calc(175px + 20rem);
                    opacity: 0;
                    visibility: hidden;

                    @include below($tablet) {
                        top: calc(175px + 6rem);
                    }

                }

            }

        }

    }

    &__info {
        transition: all 2s ease;
        position: relative;
        z-index: 1;
        left: 10rem;
        width: 55%;
        margin-top: 15rem;
        opacity: 1;
        visibility: visible;
        margin-bottom: 5rem;

        @include below($large-desktop) {
            width: auto;
            left: 0;
            margin-top: 18rem;
        }

        @include below($tablet) {
            margin-top: 8.5rem;
            margin-bottom: 2.5rem;
        }

        &.init {
            opacity: 0;
            visibility: hidden;
        }

        &-h3 {
            font-size: 35px;
            font-family: $roboto-regular;
            text-transform: uppercase;
            margin-bottom: 1.5rem;

            @include below($tablet) {
                font-size: 18px;
            }

        }

        &-content {
            display: block;
            font-size: 24px;
            line-height: 34px;

            @include below($tablet) {
                font-size: 14px;
                line-height: 22px;
            }

        }

    }

    &__callback {
        transition: all 2s ease;
        position: relative;
        z-index: 2;
        border-radius: 20px;
        text-align: center;
        background-color: $gray;
        padding: 3rem;
        margin-bottom: 5rem;
        opacity: 1;
        visibility: visible;
        box-shadow: 0 1rem 0 0 rgba(0, 0, 0, .1);

        @include below($tablet) {
            padding: 2rem;
            margin-bottom: 2.5rem;
            border-radius: 10px;
            box-shadow: 0 .5rem 0 0 rgba(0, 0, 0, .1);
        }

        &.init {
            opacity: 0;
            visibility: hidden;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
        }

        &-title {
            font-size: 35px;
            text-transform: uppercase;
            font-family: $roboto-regular;
            margin-bottom: 1.5rem;

            @include below($tablet) {
                font-size: 16px;
            }

        }

        &-content {
            max-width: 60rem;
            font-size: 24px;
            line-height: 34px;
            margin: 0 auto 1rem;

            @include below($tablet) {
                font-size: 14px;
                line-height: 22px;
            }

        }

        &-button {
            display: inline-block;
            font-family: $roboto-regular;
            font-size: 20px;
            background-color: $blue;
            border-radius: 3px;
            color: $white;
            margin: .75rem auto 0;
            padding: 1rem 1.75rem;
            text-decoration: none;

            @include below($tablet) {
                font-size: 14px;
                padding: .75rem 1.25rem;
            }

        }

    }

    &__content-row {
        position: relative;
        z-index: 1;
        margin: 0 10rem 3rem;
        padding-bottom: 3rem;
        border-bottom: 2px solid $gray;

        @include below($large-desktop) {
            margin-left: 0;
            margin-right: 0;
        }

        @include below($tablet) {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
        }

        &--trays {
            transition: all 2s ease;
            opacity: 1;
            visibility: visible;

            &.init {
                opacity: 0;
                visibility: hiidden;
            }

            &:before {
                transition: all 2s ease;
                content: '';
                position: absolute;
                z-index: -1;
                top: -10rem;
                right: 15rem;
                width: 298.97px;
                height: 286.283px;
                background: transparent url('/images/base/tray--black.webp') no-repeat 50% 50%;
                background-size: contain;

                @include below($desktop) {
                    right: 8rem;
                }

                @include below($tablet) {
                    @include size(8.5rem);
                    top: 5rem;
                    bottom: auto;
                    right: 0;
                }

            }

            &.init:before {
                top: -20rem;
                right: 25rem;

                @include below($tablet) {
                    top: 10rem;
                    right: 0;
                }

            }

            &:after {
                transition: all 2s ease;
                content: '';
                position: absolute;
                z-index: -1;
                bottom: 0;
                right: 0;
                width: 288.644px;
                height: 301.418px;
                background: transparent url('/images/base/tray--white.webp') no-repeat 50% 50%;
                background-size: contain;

                @include below($tablet) {
                    @include size(10rem);
                    top: 0;
                    right: 5rem;
                    bottom: auto;
                }

            }

            &.init:after {
                bottom: -10rem;

                @include below($tablet) {
                    top: -7rem;
                    right: 7rem;
                }

            }

        }

        &--pallet {
            transition: all 2s ease;
            opacity: 1;
            visibility: visible;
            padding-bottom: 10rem;

            @include below($tablet) {
                padding-bottom: 0;
            }

            &.init {
                opacity: 0;
                visibility: hiidden;
            }

            &:after {
                transition: all 2s ease;
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: 7rem;
                bottom: 0;
                width: 20rem;
                background: transparent url('/images/base/pallet.webp') no-repeat 50% 50%;
                background-size: contain;
                opacity: 1;
                visibility: visible;

                @include below($tablet) {
                    width: 10rem;
                    right: 0;
                }

            }

            &.init:after {
                opacity: 0;
                visibility: hidden;
            }
        }

        &:last-child {
            margin-bottom: 2rem;
            border-bottom: none;
        }

        &-title {
            font-size: 35px;
            line-height: 1.2;
            font-family: $roboto-regular;
            text-transform: uppercase;
            margin-bottom: 1rem;

            @include below($tablet) {
                font-size: 18px;
            }

        }

        &-info {
            font-size: 24px;
            line-height: 34px;
            width: 65%;

            @include below($tablet) {
                font-size: 14px;
                line-height: 22px;
            }

        }

    }

}
