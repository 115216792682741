/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 500px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 15px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}

.modal a.close-modal {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==");
}

.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@font-face {
  font-family: 'Roboto Thin';
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/fonts/roboto/Roboto-Thin.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Light';
  src: local("Roboto Light"), local("Roboto-Light"), url("/fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Regular';
  src: local("Roboto Regular"), local("Roboto-Regular"), url("/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Medium';
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

.p-0 {
  padding: 0rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-4 {
  padding: 4rem;
}

.p-5 {
  padding: 5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pr-1 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pl-5 {
  padding-left: 5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: 4rem;
}

.m-5 {
  margin: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

* {
  min-width: 0;
  min-height: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Light";
  color: rgba(0, 0, 0, 0.87);
  min-width: 320px;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  background: white;
}

.content {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  width: calc(100% - 4rem);
  max-width: 94rem;
  margin: 7rem 0 0;
}

@media all and (max-width: 768px) {
  .content {
    margin: 1rem 0 0;
  }
}

.content p {
  font-size: 1.5rem;
  line-height: 1.45;
  margin: 1.75rem 0;
}

@media all and (max-width: 768px) {
  .content p {
    font-size: .87rem;
    line-height: 1.6;
  }
}

.content p.blockquote {
  border-left: 0.5rem solid #f3f3f3;
  padding: 1rem;
}

@media all and (max-width: 768px) {
  .content p.blockquote {
    border-width: .35rem;
    padding: .5rem;
  }
}

.content p b {
  font-family: "Roboto Regular";
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.form {
  position: relative;
}

.form-grid {
  display: flex;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-errors {
  display: block;
  color: #dc3545;
  font-size: .8rem;
  margin-bottom: 2rem;
  cursor: default;
  line-height: 1.2;
}

.form-errors > p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.form-control {
  transition: all .25s ease;
  display: block;
  width: 100%;
  padding: 0 .75rem;
  line-height: 2.2;
  height: 2.2rem;
  color: #495057;
  font-size: 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  background-color: #fff;
  border-color: #e7c7b4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 114, 41, 0.15);
}

.form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control::-ms-placeholder, .form-control::-ms-input-placeholder, .form-control::placeholder, .form-control[placeholder] {
  color: #6c757d;
  opacity: .1;
}

.form-control:disabled, .form-control[readonly], .form-control.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control + label.error, .form-control + label.error + span.error, .form-control + span.error, .form-control + .error + .error {
  display: block;
  color: #dc3545;
  font-size: .8rem;
  margin-top: .5rem;
  cursor: default;
}

.form-control__cost {
  position: relative;
}

.form-control__cost-placeholder {
  position: absolute;
  top: 50%;
  left: .75rem;
  font-size: .8rem;
  transform: translateY(-50%);
  color: black;
}

.form-control__cost-input {
  padding-left: 2rem;
}

.form-control__cost-input::placeholder {
  color: #909090;
  opacity: .5;
}

.form-control__textarea {
  height: 10rem;
  font-family: "Roboto Light";
  line-height: 1.5;
  padding: .5rem .75rem;
  resize: none;
}

.form-control__textarea--small {
  height: 5.6rem;
}

.form-label {
  display: block;
  font-size: .8rem;
  margin-bottom: .5rem;
}

.form-label--required {
  position: relative;
}

.form-label--required:after {
  content: "*";
  margin: -.3rem 0 0 .15rem;
  position: absolute;
  color: #dc3545;
  font-size: 1.5rem;
}

.checkbox {
  transition: all .25s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox:hover {
  color: #1f7ebd;
}

@media all and (min-width: 1110px) and (hover: hover) {
  .checkbox:hover .checkbox__icon {
    border-color: #909090;
  }
}

.checkbox--color {
  font-size: 0;
  display: inline-block;
  margin: 5px 4px !important;
}

.checkbox--color .checkbox__icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: 0;
}

.checkbox--color .checkbox__icon:after {
  display: none;
}

.checkbox--color .checkbox__icon:before {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: transparent;
  border: 2px solid transparent;
}

.checkbox--color input:checked ~ .checkbox__icon:before, .checkbox--color input:checked ~ .checkbox__icon:after {
  background-color: transparent;
  border-color: #1f7ebd;
}

.checkbox--color.is-disabled {
  display: none;
}

.checkbox__icon {
  transition: all .25s ease;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: .5rem;
  background: #ffffff;
  border: 1px solid #ccd4d8;
}

.checkbox__icon:after {
  transition: all .25s ease;
  border-radius: 2px;
  content: "";
  position: absolute;
  width: .6rem;
  height: .6rem;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.checkbox.is-disabled {
  pointer-events: none;
  color: #cdcdcd;
}

.is-disabled .checkbox__icon {
  background: #f1f1f1;
}

.checkbox input {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.checkbox input:checked ~ .checkbox__icon:after {
  background-color: #1f7ebd;
}

.checkbox.is-disabled input:checked ~ .checkbox__icon:after {
  background-color: #909090 !important;
}

.radio {
  transition: all .25s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio:hover {
  color: #1f7ebd;
}

@media all and (min-width: 1110px) and (hover: hover) {
  .radio:hover .radio__icon {
    border-color: #909090;
  }
}

.radio__icon {
  transition: all .25s ease;
  border-radius: 100%;
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: .5rem;
  background: #ffffff;
  border: 1px solid #ccd4d8;
}

.radio__icon:after {
  transition: all .25s ease;
  border-radius: 100%;
  content: "";
  position: absolute;
  width: .6rem;
  height: .6rem;
  top: calc(50% + 0.01rem);
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.radio.is-disabled {
  pointer-events: none;
  color: #cdcdcd;
}

.is-disabled .radio__icon {
  background: #f1f1f1;
}

.radio input {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.radio input:checked ~ .radio__icon:after {
  background-color: #1f7ebd;
}

.button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all .25s ease;
  display: inline-block;
  line-height: 1.25;
  font-size: 1rem;
  color: #909090;
  text-align: center;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  vertical-align: middle;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
}

.button:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
}

.button--blue {
  background-color: #1f7ebd;
  border: 1px solid #1f7ebd;
  color: white;
}

.button--blue:hover {
  color: white;
  text-decoration: none;
  opacity: .8;
}

.button--blue.button--outline {
  color: #1f7ebd;
}

.button--blue.button--outline:hover {
  color: white;
  background-color: #1f7ebd;
  opacity: 1;
}

.button--xs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: .7rem;
  line-height: .9;
}

.button--small {
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  font-size: .80rem;
}

.button--large {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-size: 1.2rem;
}

.button--xl {
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: inline-block;
  font-size: 1.4rem;
}

.button--disabled {
  color: black;
  cursor: default;
  cursor: not-allowed;
  background-color: #909090;
  opacity: .35;
  border: 1px solid transparent;
  pointer-events: none;
}

.button--disabled:hover {
  opacity: .35;
  color: black;
  text-decoration: none;
}

.button--outline {
  background-color: transparent;
}

.preloader {
  transition: all .25s ease;
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translate(0, -50%);
}

.preloader img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  margin: auto;
}

.has-preloader-backdrop > *:not(.preloader) {
  transition: all .25s ease;
  pointer-events: none;
  opacity: .3;
}

.loader {
  transition: all .25s ease;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
}

.loader--is-hidden {
  display: none;
}

.loader--is-visibility-hidden {
  opacity: 0;
}

.loader:before {
  width: 64px;
  height: 64px;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background: url("/images/base/icons/loader.svg") no-repeat 50% 50%;
  transform: translate(-50%);
}

.blocker {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.87);
}

.modal {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  max-width: 56rem;
  box-shadow: none;
  width: 100%;
}

@media all and (max-width: 1750px) {
  .modal {
    padding-top: 3.8rem;
    padding-bottom: 3.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media all and (max-width: 1440px) {
  .modal {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
}

@media all and (max-width: 1280px) {
  .modal {
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
}

@media all and (max-width: 1110px) {
  .modal {
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

@media all and (max-width: 900px) {
  .modal {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
}

@media all and (max-width: 768px) {
  .modal {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (max-width: 600px) {
  .modal {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.modal--m {
  max-width: 40rem;
}

.modal--s {
  max-width: 30rem;
}

.modal--xs {
  max-width: 24rem;
}

.modal a.close-modal {
  transition: all .25s ease;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: url("/images/base/icons/close.svg") no-repeat 50% 50%;
  background-size: auto auto;
  opacity: .5;
}

.modal a.close-modal:hover {
  opacity: 1;
}

.modal__title {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: .7rem;
  font-family: "Roboto Regular";
}

@media all and (max-width: 1440px) {
  .modal__title {
    font-size: 1.25rem;
  }
}

@media all and (max-width: 1110px) {
  .modal__title {
    font-size: 1.2rem;
  }
}

@media all and (max-width: 600px) {
  .modal__title {
    margin-bottom: .5rem;
  }
}

.modal__subtitle {
  font-size: .95rem;
  line-height: 1.3;
}

@media all and (max-width: 1440px) {
  .modal__subtitle {
    line-height: 1.15;
  }
}

@media all and (max-width: 600px) {
  .modal__subtitle {
    font-size: .8rem;
    line-height: 1.4;
  }
}

.modal__head {
  font-size: 1rem;
  line-height: 1.75;
  margin-bottom: 1.5rem;
}

.modal__body {
  line-height: 1.4;
}

.modal__body > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.link {
  transition: all .25s ease;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  border-bottom: 2px solid #1f7ebd;
}

.link:hover {
  color: #1f7ebd;
}

.header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: calc(100% - 4rem);
  max-width: 94rem;
  padding: 2rem 0 1.75rem;
}

@media all and (max-width: 768px) {
  .header {
    display: block;
    padding: 1.65rem 0 1.25rem;
  }
}

.header--blue .header-logotype {
  background-image: url("/images/base/logotype--white.svg");
}

.header--blue .header-phone {
  color: white;
  background-image: url("/images/base/phone--white.svg");
}

.header--blue .header-language {
  color: white;
}

.header--blue .header-language:hover {
  color: white;
}

.header-rectangle {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.header-rectangle:before {
  width: 2000px;
  height: 19rem;
  content: '';
  position: absolute;
  top: -21rem;
  z-index: 0;
  background-color: #f3f3f3;
  border-radius: 20px;
  transform: rotate(-18deg);
}

@media all and (max-width: 1750px) {
  .header-rectangle:before {
    top: -22.5rem;
    left: -4rem;
  }
}

@media all and (max-width: 768px) {
  .header-rectangle:before {
    top: -28.5rem;
    left: -4rem;
  }
}

.header-rectangle--blue:before {
  top: -20rem;
  height: 49rem;
  background: linear-gradient(to right, #1F7EBD 0%, #103F5F 100%);
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.3);
}

@media all and (max-width: 1750px) {
  .header-rectangle--blue:before {
    left: -6rem;
  }
}

@media all and (max-width: 768px) {
  .header-rectangle--blue:before {
    top: -36rem;
    left: -8rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
  }
}

.header-logotype {
  position: relative;
  z-index: 1;
  display: block;
  width: 275px;
  height: 66.763px;
  background: transparent url("/images/base/logotype--blue.svg") no-repeat 50% 50%;
  background-size: 100%;
  font-size: 0;
  flex-shrink: 0;
}

@media all and (max-width: 768px) {
  .header-logotype {
    width: 183.88px;
    height: 44.64px;
  }
}

.header-phone {
  transition: all .25s ease;
  position: relative;
  z-index: 1;
  font-family: "Roboto Regular";
  font-size: 2rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  margin-left: auto;
  padding-left: 2.15rem;
  background: transparent url("/images/base/phone--black.svg") no-repeat 0 50%;
}

@media all and (max-width: 768px) {
  .header-phone {
    position: absolute;
    bottom: 1.25rem;
    right: 0;
    font-size: 0;
    width: 16px;
    height: 16px;
    padding-left: 0;
    background-position: 50% 50%;
    background-size: 100%;
  }
}

.header-language {
  transition: all .25s ease;
  position: relative;
  z-index: 1;
  margin-left: 4rem;
  flex-shrink: 0;
  font-size: 1.25rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  display: none;
  font-family: "Roboto Regular";
}

@media all and (max-width: 768px) {
  .header-language {
    position: absolute;
    top: 1.65rem;
    right: 0;
    font-size: 1rem;
  }
}

.header-language.on {
  display: block;
}

.header-language:hover {
  color: #1f7ebd;
}

.nav {
  position: relative;
  z-index: 1;
  width: calc(100% - 4rem);
  max-width: 94rem;
  font-size: 0;
}

.nav-item {
  display: inline-block;
  font-size: 1.6rem;
  margin: .35rem 3.5rem .35rem 0;
  color: rgba(0, 0, 0, 0.87);
}

@media all and (max-width: 768px) {
  .nav-item {
    font-size: 1rem;
    margin: .25rem 1rem .25rem 0;
  }
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item--active {
  font-family: "Roboto Medium";
  border-color: transparent;
}

.nav-item--active:hover {
  color: rgba(0, 0, 0, 0.87);
}

.nav--white .nav-item {
  color: white;
  border-color: white;
}

.footer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4rem);
  max-width: 94rem;
  flex-shrink: 0;
  margin: 2rem 2rem 0;
  padding: 2rem 0;
  border-top: 2px solid #f3f3f3;
}

@media all and (max-width: 768px) {
  .footer {
    width: 100%;
    max-width: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 2rem 1.75rem;
    margin-top: 1rem;
  }
}

.footer-copyright {
  color: #909090;
}

@media all and (max-width: 768px) {
  .footer-copyright {
    font-size: .8rem;
    margin-bottom: .75rem;
  }
}

.footer-phone {
  transition: all .25s ease;
  font-family: "Roboto Regular";
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  font-size: 1.8rem;
}

@media all and (max-width: 768px) {
  .footer-phone {
    font-size: 1.5rem;
    padding-bottom: .15rem;
    border-bottom: 2px solid #1f7ebd;
    margin-bottom: .5rem;
  }
  .footer-phone:hover {
    color: #1f7ebd;
  }
}

@media all and (max-width: 768px) {
  .footer-email {
    font-size: .8rem;
    font-family: "Roboto Regular";
  }
}

.main {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  width: calc(100% - 4rem);
  max-width: 94rem;
  margin-top: 3.5rem;
}

@media all and (max-width: 768px) {
  .main {
    margin-top: 1rem;
  }
}

.main__cover {
  position: relative;
  margin-top: 2rem;
}

.main__cover-title {
  transition: all 1s ease;
  position: relative;
  z-index: 1;
  display: inline-block;
  background-color: white;
  text-transform: uppercase;
  font-family: "Roboto Regular";
  color: rgba(0, 0, 0, 0.87);
  font-size: 35px;
  padding: .85rem 1.5rem .7rem;
  border-radius: 3px;
  transform-style: preserve-3d;
  margin-bottom: 3rem;
}

@media all and (max-width: 768px) {
  .main__cover-title {
    font-size: 24px;
    margin-bottom: 4.5rem;
  }
}

.main__cover-title.init {
  color: white;
}

.main__cover-title:before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  border-radius: 3px;
  transform: rotate(5deg) scale(0.95) translateZ(-1px);
  opacity: .2;
}

.main__cover-title-sub {
  transition: all 1s ease;
  position: absolute;
  color: white;
  font-size: 22px;
  text-transform: none;
  font-family: "Roboto Thin";
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.2);
  padding: .2rem .75rem;
  border-radius: 2px;
  opacity: 1;
  visibility: visible;
}

@media all and (max-width: 768px) {
  .main__cover-title-sub {
    left: 0;
    top: 100%;
    font-size: 18px;
  }
}

.main__cover-title-sub.init {
  opacity: 0;
  visibility: hidden;
}

.main__cover-title-sub--first, .main__cover-title-sub--second {
  margin-left: 2.5rem;
}

@media all and (max-width: 768px) {
  .main__cover-title-sub--first, .main__cover-title-sub--second {
    margin-left: 0;
  }
}

.main__cover-title-sub--first {
  top: 0;
}

@media all and (max-width: 768px) {
  .main__cover-title-sub--first {
    top: calc(100% + .15rem);
  }
}

.main__cover-title-sub--second {
  bottom: 0;
}

@media all and (max-width: 768px) {
  .main__cover-title-sub--second {
    bottom: auto;
    top: calc(100% + 1.8rem);
  }
}

.main__cover-intro {
  transition: all 2s ease;
  position: relative;
  z-index: 1;
  color: white;
  font-size: 24px;
  line-height: 34px;
  max-width: 45%;
  margin-bottom: 2rem;
  opacity: 1;
  visibility: visible;
}

@media all and (max-width: 1750px) {
  .main__cover-intro {
    max-width: 50%;
  }
}

@media all and (max-width: 768px) {
  .main__cover-intro {
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}

.main__cover-intro.init {
  opacity: 0;
  visibility: hidden;
}

.main__cover-rolls-wrapper {
  position: absolute;
  top: -10rem;
  right: 0;
  width: 60%;
  height: 63rem;
  overflow: hidden;
}

@media all and (max-width: 1110px) {
  .main__cover-rolls-wrapper {
    top: -5rem;
    height: 45rem;
  }
}

@media all and (max-width: 768px) {
  .main__cover-rolls-wrapper {
    top: 7rem;
    right: -2rem;
    height: 32rem;
  }
}

.main__cover-rolls-film {
  transition: all 2s ease;
  position: absolute;
  z-index: 1;
  top: 10rem;
  right: 0;
  width: 738px;
  height: 540px;
}

@media all and (max-width: 1440px) {
  .main__cover-rolls-film {
    top: 12rem;
    width: 500px;
    height: 366px;
  }
}

@media all and (max-width: 1110px) {
  .main__cover-rolls-film {
    top: 15rem;
    width: 350px;
    height: 256px;
  }
}

@media all and (max-width: 768px) {
  .main__cover-rolls-film {
    right: auto;
    left: 1rem;
    top: 4.5rem;
    width: 300px;
    height: 219px;
  }
}

.main__cover-rolls-film.init {
  top: 0;
}

.main__cover-rolls-shadow {
  transition: all 2s ease;
  position: absolute;
  top: calc(175px + 10rem);
  right: 0;
  width: 808px;
  height: 503px;
  opacity: 1;
  visibility: visible;
}

@media all and (max-width: 1440px) {
  .main__cover-rolls-shadow {
    width: 540px;
    height: 336px;
  }
}

@media all and (max-width: 1110px) {
  .main__cover-rolls-shadow {
    width: 370px;
    height: 231px;
  }
}

@media all and (max-width: 768px) {
  .main__cover-rolls-shadow {
    right: auto;
    left: 0;
    top: 10rem;
    width: 300px;
    height: 187px;
  }
}

.main__cover-rolls-shadow.init {
  top: calc(175px + 20rem);
  opacity: 0;
  visibility: hidden;
}

@media all and (max-width: 768px) {
  .main__cover-rolls-shadow.init {
    top: calc(175px + 6rem);
  }
}

.main__info {
  transition: all 2s ease;
  position: relative;
  z-index: 1;
  left: 10rem;
  width: 55%;
  margin-top: 15rem;
  opacity: 1;
  visibility: visible;
  margin-bottom: 5rem;
}

@media all and (max-width: 1440px) {
  .main__info {
    width: auto;
    left: 0;
    margin-top: 18rem;
  }
}

@media all and (max-width: 768px) {
  .main__info {
    margin-top: 8.5rem;
    margin-bottom: 2.5rem;
  }
}

.main__info.init {
  opacity: 0;
  visibility: hidden;
}

.main__info-h3 {
  font-size: 35px;
  font-family: "Roboto Regular";
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

@media all and (max-width: 768px) {
  .main__info-h3 {
    font-size: 18px;
  }
}

.main__info-content {
  display: block;
  font-size: 24px;
  line-height: 34px;
}

@media all and (max-width: 768px) {
  .main__info-content {
    font-size: 14px;
    line-height: 22px;
  }
}

.main__callback {
  transition: all 2s ease;
  position: relative;
  z-index: 2;
  border-radius: 20px;
  text-align: center;
  background-color: #f3f3f3;
  padding: 3rem;
  margin-bottom: 5rem;
  opacity: 1;
  visibility: visible;
  box-shadow: 0 1rem 0 0 rgba(0, 0, 0, 0.1);
}

@media all and (max-width: 768px) {
  .main__callback {
    padding: 2rem;
    margin-bottom: 2.5rem;
    border-radius: 10px;
    box-shadow: 0 0.5rem 0 0 rgba(0, 0, 0, 0.1);
  }
}

.main__callback.init {
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
}

.main__callback-title {
  font-size: 35px;
  text-transform: uppercase;
  font-family: "Roboto Regular";
  margin-bottom: 1.5rem;
}

@media all and (max-width: 768px) {
  .main__callback-title {
    font-size: 16px;
  }
}

.main__callback-content {
  max-width: 60rem;
  font-size: 24px;
  line-height: 34px;
  margin: 0 auto 1rem;
}

@media all and (max-width: 768px) {
  .main__callback-content {
    font-size: 14px;
    line-height: 22px;
  }
}

.main__callback-button {
  display: inline-block;
  font-family: "Roboto Regular";
  font-size: 20px;
  background-color: #1f7ebd;
  border-radius: 3px;
  color: white;
  margin: .75rem auto 0;
  padding: 1rem 1.75rem;
  text-decoration: none;
}

@media all and (max-width: 768px) {
  .main__callback-button {
    font-size: 14px;
    padding: .75rem 1.25rem;
  }
}

.main__content-row {
  position: relative;
  z-index: 1;
  margin: 0 10rem 3rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid #f3f3f3;
}

@media all and (max-width: 1440px) {
  .main__content-row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media all and (max-width: 768px) {
  .main__content-row {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
}

.main__content-row--trays {
  transition: all 2s ease;
  opacity: 1;
  visibility: visible;
}

.main__content-row--trays.init {
  opacity: 0;
  visibility: hiidden;
}

.main__content-row--trays:before {
  transition: all 2s ease;
  content: '';
  position: absolute;
  z-index: -1;
  top: -10rem;
  right: 15rem;
  width: 298.97px;
  height: 286.283px;
  background: transparent url("/images/base/tray--black.webp") no-repeat 50% 50%;
  background-size: contain;
}

@media all and (max-width: 1110px) {
  .main__content-row--trays:before {
    right: 8rem;
  }
}

@media all and (max-width: 768px) {
  .main__content-row--trays:before {
    width: 8.5rem;
    height: 8.5rem;
    top: 5rem;
    bottom: auto;
    right: 0;
  }
}

.main__content-row--trays.init:before {
  top: -20rem;
  right: 25rem;
}

@media all and (max-width: 768px) {
  .main__content-row--trays.init:before {
    top: 10rem;
    right: 0;
  }
}

.main__content-row--trays:after {
  transition: all 2s ease;
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 288.644px;
  height: 301.418px;
  background: transparent url("/images/base/tray--white.webp") no-repeat 50% 50%;
  background-size: contain;
}

@media all and (max-width: 768px) {
  .main__content-row--trays:after {
    width: 10rem;
    height: 10rem;
    top: 0;
    right: 5rem;
    bottom: auto;
  }
}

.main__content-row--trays.init:after {
  bottom: -10rem;
}

@media all and (max-width: 768px) {
  .main__content-row--trays.init:after {
    top: -7rem;
    right: 7rem;
  }
}

.main__content-row--pallet {
  transition: all 2s ease;
  opacity: 1;
  visibility: visible;
  padding-bottom: 10rem;
}

@media all and (max-width: 768px) {
  .main__content-row--pallet {
    padding-bottom: 0;
  }
}

.main__content-row--pallet.init {
  opacity: 0;
  visibility: hiidden;
}

.main__content-row--pallet:after {
  transition: all 2s ease;
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 7rem;
  bottom: 0;
  width: 20rem;
  background: transparent url("/images/base/pallet.webp") no-repeat 50% 50%;
  background-size: contain;
  opacity: 1;
  visibility: visible;
}

@media all and (max-width: 768px) {
  .main__content-row--pallet:after {
    width: 10rem;
    right: 0;
  }
}

.main__content-row--pallet.init:after {
  opacity: 0;
  visibility: hidden;
}

.main__content-row:last-child {
  margin-bottom: 2rem;
  border-bottom: none;
}

.main__content-row-title {
  font-size: 35px;
  line-height: 1.2;
  font-family: "Roboto Regular";
  text-transform: uppercase;
  margin-bottom: 1rem;
}

@media all and (max-width: 768px) {
  .main__content-row-title {
    font-size: 18px;
  }
}

.main__content-row-info {
  font-size: 24px;
  line-height: 34px;
  width: 65%;
}

@media all and (max-width: 768px) {
  .main__content-row-info {
    font-size: 14px;
    line-height: 22px;
  }
}

.contacts-phone-link {
  transition: all .25s ease;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

@media all and (max-width: 768px) {
  .contacts-phone-link {
    border-bottom: 2px solid #1f7ebd;
    display: inline-block;
    line-height: 1;
  }
}

.contacts-phone-link:hover {
  color: #1f7ebd;
}

.contacts-email-link {
  transition: all .25s ease;
  border-bottom: 2px solid #1f7ebd;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  line-height: 1;
}

.contacts-email-link:hover {
  color: #1f7ebd;
}

.contacts-navigator-container {
  display: none;
}

@media all and (max-width: 768px) {
  .contacts-navigator-container {
    display: block;
  }
}

.contacts-navigator-link {
  position: relative;
  display: inline-block;
  border-bottom: 2px solid #1f7ebd;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  margin-left: 1.25rem;
}

.contacts-navigator-link:before {
  width: 14.5px;
  height: 14.5px;
  content: '';
  position: absolute;
  left: -1.25rem;
  background: transparent url("/images/base/wheel.svg") no-repeat 50% 50%;
  background-size: 100%;
}

.contacts-map {
  margin: 2rem 0;
}
