.blocker {
	z-index: 100;
	background-color: rgba(0, 0, 0, .4);
	color: $base;
}

.modal {
	@include padding-y(4rem);
	@include padding-x(3.2rem);
	max-width: 56rem;
	box-shadow: none;
	width: 100%;

	@include below($big-desktop) {
		@include padding-y(3.8rem);
		@include padding-x(3rem);
	}

	@include below($large-desktop) {
		@include padding-y(3.6rem);
		@include padding-x(2.8rem);
	}

	@include below($mid-desktop) {
		@include padding-y(3.4rem);
		@include padding-x(2.6rem);
	}

	@include below($desktop) {
		@include padding-y(3.4rem);
		@include padding-x(2.4rem);
	}

	@include below($tablet-landscape) {
		@include padding-y(3.2rem);
		@include padding-x(2.2rem);
	}

	@include below($tablet) {
		@include padding-y(3.2rem);
		@include padding-x(2rem);
	}

	@include below($mobile) {
		@include padding-y(3.2rem);
		@include padding-x(1rem);
	}

	&--m {
		max-width: 40rem;
	}

	&--s {
		max-width: 30rem;
	}

	&--xs {
		max-width: 24rem;
	}

	a.close-modal {
		@include transition();
		@include size(1.5rem);
		position: absolute;
		top: 1rem;
		right: 1rem;
		background: url('/images/base/icons/close.svg') no-repeat 50% 50%;
		background-size: auto auto;
		opacity: .5;

		&:hover {
			opacity: 1;
		}

	}

	&__title {
		font-size: 1.5rem;
		line-height: 1;
		margin-bottom: .7rem;
        font-family: $roboto-regular;

		@include below($large-desktop) {
			font-size: 1.25rem;
		}

		@include below($desktop) {
			font-size: 1.2rem;
		}

		@include below($mobile) {
			margin-bottom: .5rem;
		}

	}

	&__subtitle {
		font-size: .95rem;
		line-height: 1.3;

		@include below($large-desktop) {
			line-height: 1.15;
		}

		@include below($mobile) {
			font-size: .8rem;
			line-height: 1.4;
		}

	}

	&__head {
		font-size: 1rem;
		line-height: 1.75;
		margin-bottom: 1.5rem;
	}

	&__body {
		line-height: 1.4;

		& > p {
			@include margin-y(1rem);
		}

	}

}
