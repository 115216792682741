.button {

    @include padding-y(.5rem);
    @include padding-x(1rem);
    @include transition();
    display: inline-block;
    line-height: 1.25;
    font-size: 1rem;
    color: $dark-gray;
    text-align: center;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;

    &:hover {
        color: $base;
        text-decoration: underline;
    }

    &--blue {
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;

        &:hover {
            color: $white;
            text-decoration: none;
            opacity: .8;
        }

        &.button--outline {
            color: $blue;

            &:hover {
                color: $white;
                background-color: $blue;
                opacity: 1;
            }

        }

    }

    &--xs {
        @include padding-x(.5rem);
        font-size: .7rem;
        line-height: .9;
    }

    &--small {
        @include padding-x(.85rem);
        font-size: .80rem;
    }

    &--large {
        @include padding-y(.65rem);
        @include padding-x(1.2rem);
        font-size: 1.2rem;
    }

    &--xl {
        @include padding-y(.85rem);
        @include padding-x(1.5rem);
        display: inline-block;
        font-size: 1.4rem;
    }

    &--disabled {
        color: $black;
        cursor: default;
        cursor: not-allowed;
        background-color: $dark-gray;
        opacity: .35;
        border: 1px solid transparent;
        pointer-events: none;

        &:hover {
            opacity: .35;
            color: $black;
            text-decoration: none;
        }

    }

    &--outline {
        background-color: transparent;
    }

}
