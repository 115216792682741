.p {

    &-0 { padding: 0rem }
    &-1 { padding: 1rem }
    &-2 { padding: 2rem }
    &-3 { padding: 3rem }
    &-4 { padding: 4rem }
    &-5 { padding: 5rem }

    &t {
        &-0 { padding-top: 0 }
        &-1 { padding-top: 1rem }
        &-2 { padding-top: 2rem }
        &-3 { padding-top: 3rem }
        &-4 { padding-top: 4rem }
        &-5 { padding-top: 5rem }
    }

    &r {
        &-1 { padding-right: 0 }
        &-1 { padding-right: 1rem }
        &-2 { padding-right: 2rem }
        &-3 { padding-right: 3rem }
        &-4 { padding-right: 4rem }
        &-5 { padding-right: 5rem }
    }

    &b {
        &-0 { padding-bottom: 0 }
        &-1 { padding-bottom: 1rem }
        &-2 { padding-bottom: 2rem }
        &-3 { padding-bottom: 3rem }
        &-4 { padding-bottom: 4rem }
        &-5 { padding-bottom: 5rem }
    }

    &l {
        &-0 { padding-left: 0 }
        &-1 { padding-left: 1rem }
        &-2 { padding-left: 2rem }
        &-3 { padding-left: 3rem }
        &-4 { padding-left: 4rem }
        &-5 { padding-left: 5rem }
    }

    &x {
        &-0 { @include padding-x(0) }
        &-1 { @include padding-x(1rem) }
        &-2 { @include padding-x(2rem) }
        &-3 { @include padding-x(3rem) }
        &-4 { @include padding-x(4rem) }
        &-5 { @include padding-x(5rem) }
    }

    &y {
        &-0 { @include padding-y(0) }
        &-1 { @include padding-y(1rem) }
        &-2 { @include padding-y(2rem) }
        &-3 { @include padding-y(3rem) }
        &-4 { @include padding-y(4rem) }
        &-5 { @include padding-y(5rem) }
    }

}

.m {

    &-0 { margin: 0 }
    &-1 { margin: 1rem }
    &-2 { margin: 2rem }
    &-3 { margin: 3rem }
    &-4 { margin: 4rem }
    &-5 { margin: 5rem }

    &t {
        &-0 { margin-top: 0 }
        &-1 { margin-top: 1rem }
        &-2 { margin-top: 2rem }
        &-3 { margin-top: 3rem }
        &-4 { margin-top: 4rem }
        &-5 { margin-top: 5rem }
    }

    &r {
        &-0 { margin-right: 0 }
        &-1 { margin-right: 1rem }
        &-2 { margin-right: 2rem }
        &-3 { margin-right: 3rem }
        &-4 { margin-right: 4rem }
        &-5 { margin-right: 5rem }
    }

    &b {
        &-0 { margin-bottom: 0 }
        &-1 { margin-bottom: 1rem }
        &-2 { margin-bottom: 2rem }
        &-3 { margin-bottom: 3rem }
        &-4 { margin-bottom: 4rem }
        &-5 { margin-bottom: 5rem }
    }

    &l {
        &-0 { margin-left: 0 }
        &-1 { margin-left: 1rem }
        &-2 { margin-left: 2rem }
        &-3 { margin-left: 3rem }
        &-4 { margin-left: 4rem }
        &-5 { margin-left: 5rem }
    }

    &x {
        &-0 { @include margin-x(0) }
        &-1 { @include margin-x(1rem) }
        &-2 { @include margin-x(2rem) }
        &-3 { @include margin-x(3rem) }
        &-4 { @include margin-x(4rem) }
        &-5 { @include margin-x(5rem) }
    }

    &y {
        &-0 { @include margin-y(0) }
        &-1 { @include margin-y(1rem) }
        &-2 { @include margin-y(2rem) }
        &-3 { @include margin-y(3rem) }
        &-4 { @include margin-y(4rem) }
        &-5 { @include margin-y(5rem) }
    }

}
