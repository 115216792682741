.footer {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 4rem);
    max-width: 94rem;
    flex-shrink: 0;
    margin: 2rem 2rem 0;
    padding: 2rem 0;
    border-top: 2px solid $gray;

    @include below($tablet) {
        width: 100%;
        max-width: auto;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.25rem 2rem 1.75rem;
        margin-top: 1rem;
    }

    &-copyright {
        color: $dark-gray;

        @include below($tablet) {
            font-size: .8rem;
            margin-bottom: .75rem;
        }

    }

    &-phone {
        @include transition();
        font-family: $roboto-regular;
        color: $base;
        text-decoration: none;
        font-size: 1.8rem;

        @include below($tablet) {
            font-size: 1.5rem;
            padding-bottom: .15rem;
            border-bottom: 2px solid $blue;
            margin-bottom: .5rem;

            &:hover {
                color: $blue;
            }

        }

    }

    &-email {
        @include below($tablet) {
            font-size: .8rem;
            font-family: $roboto-regular;
        }
    }

}
