.header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    width: calc(100% - 4rem);
    max-width: 94rem;
    padding: 2rem 0 1.75rem;

    @include below($tablet) {
        display: block;
        padding: 1.65rem 0 1.25rem;
    }

    &--blue {

        & .header-logotype {
            background-image: url('/images/base/logotype--white.svg');

            &.en {
                //background-image: url('/images/base/logotype--white--en.svg');
            }

        }

        & .header-phone {
            color: $white;
            background-image: url('/images/base/phone--white.svg');
        }

        & .header-language {
            color: $white;

            &:hover {
                color: $white;
            }

        }

    }

    &-rectangle {
        @include size(100%);
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &:before {
            @include size(2000px, 19rem);
            content: '';
            position: absolute;
            top: -21rem;
            z-index: 0;
            background-color: $gray;
            border-radius: 20px;
            transform: rotate(-18deg);

            @include below($big-desktop) {
                top: -22.5rem;
                left: -4rem;
            }

            @include below($tablet) {
                top: -28.5rem;
                left: -4rem;
            }

        }

        &--blue {
            &:before {
                top: -20rem;
                height: 49rem;
                background: linear-gradient(to right, #1F7EBD 0%, #103F5F 100%);
                box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .3);

                @include below($big-desktop) {
                    left: -6rem;
                }

                @include below($tablet) {
                    top: -36rem;
                    left: -8rem;
                    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .4);
                }

            }
        }

    }

    &-logotype {
        position: relative;
        z-index: 1;
        display: block;
        width: 275px;
        height: 66.763px;
        background: transparent url('/images/base/logotype--blue.svg') no-repeat 50% 50%;
        background-size: 100%;
        font-size: 0;
        flex-shrink: 0;

        @include below($tablet) {
            width: 183.88px;
            height: 44.64px;
        }

        &.en {
            //background-image: url('/images/base/logotype--blue--en.svg');
        }

    }

    &-phone {
        @include transition();
        position: relative;
        z-index: 1;
        font-family: $roboto-regular;
        font-size: 2rem;
        text-decoration: none;
        color: $base;
        margin-left: auto;
        padding-left: 2.15rem;
        background: transparent url('/images/base/phone--black.svg') no-repeat 0 50%;

        @include below($tablet) {
            position: absolute;
            bottom: 1.25rem;
            right: 0;
            font-size: 0;
            width: 16px;
            height: 16px;
            padding-left: 0;
            background-position: 50% 50%;
            background-size: 100%;
        }

    }

    &-language {
        @include transition();
        position: relative;
        z-index: 1;
        margin-left: 4rem;
        flex-shrink: 0;
        font-size: 1.25rem;
        text-decoration: none;
        color: $base;
        display: none;
        font-family: $roboto-regular;

        @include below($tablet) {
            position: absolute;
            top: 1.65rem;
            right: 0;
            font-size: 1rem;

        }

        &.on {
            display: block;
        }

        &:hover {
            color: $blue;
        }

    }

}
