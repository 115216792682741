.loader {
    @include transition();
    @include size(100%);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    opacity: 1;
    background-color: rgba(0, 0, 0, .4);

    &--is-hidden {
        display: none;
    }

    &--is-visibility-hidden {
        opacity: 0;
    }

    &:before {
        @include size(64px);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background: url('/images/base/icons/loader.svg') no-repeat 50% 50%;
        transform: translate(-50%);
    }

}
