.form {

    position: relative;

    &-grid {
        display: flex;
    }

    &-group {
        margin-bottom: 1.5rem;
    }

    &-errors {
        display: block;
        color: $red;
        font-size: .8rem;
        margin-bottom: 2rem;
        cursor: default;
        line-height: 1.2;

        & > p {
            @include margin-y(.5rem);
        }

    }

    &-control {
        @include transition();
        display: block;
        width: 100%;
        padding: 0 .75rem;
        line-height: 2.2;
        height: 2.2rem;
        color: #495057;
        font-size: 1rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;

        &::-ms-expand {
            background-color: transparent;
            border: 0;
        }

        &:focus {
            background-color: #fff;
            border-color: #e7c7b4;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(237, 114, 41, 0.15);
        }

        &::-webkit-input-placeholder, &::-moz-placeholder, &::-ms-placeholder, &::-ms-input-placeholder, &::placeholder, &[placeholder] {
            color: #6c757d;
            opacity: .1;
        }

        &:disabled, &[readonly], &.disabled {
            background-color: #e9ecef;
            opacity: 1;
        }

        & + label.error, & + label.error + span.error, & + span.error, & + .error + .error {
            display: block;
            color: $red;
            font-size: .8rem;
            margin-top: .5rem;
            cursor: default;
        }

        &__cost {

            position: relative;

            &-placeholder {
                position: absolute;
                top: 50%;
                left: .75rem;
                font-size: .8rem;
                transform: translateY(-50%);
                color: $black;
            }

            &-input {
                padding-left: 2rem;

                &::placeholder {
                    color: $dark-gray;
                    opacity: .5;
                }

            }

        }

        &__textarea {
            height: 10rem;
            font-family: $roboto-light;
            line-height: 1.5;
            padding: .5rem .75rem;
            resize: none;

            &--small {
                height: 5.6rem;
            }

        }

    }

    &-label {
        display: block;
        font-size: .8rem;
        margin-bottom: .5rem;

        &--required {

            position: relative;

            &:after {
                content: "*";
                margin: -.3rem 0 0 .15rem;
                position: absolute;
                color: $red;
                font-size: 1.5rem;

            }
        }

    }

}

.checkbox {
    @include transition();
    display: flex;
	align-items: center;

	cursor: pointer;

    &:hover {
        color: $blue;
    }

	&:hover &__icon {
		@include above($desktop) {
			@media (hover: hover) {
				border-color: $dark-gray;
			}
		}
	}

    &--color {
        font-size: 0;
        display: inline-block;
        margin: 5px 4px !important;

        & .checkbox__icon {
            @include size(30px);
            border-radius: 100%;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            margin-right: 0;

            &:after {
                display: none;
            }

            &:before {
                @include size(38px);
                border-radius: 100%;
                content: '';
                position: absolute;
                top: -5px;
                left: -5px;
                background-color: transparent;
                border: 2px solid transparent;
            }

        }

        & input:checked {
			& ~ .checkbox__icon:before, & ~ .checkbox__icon:after {
				background-color: transparent;
                border-color: $blue;
			}
        }

        &.is-disabled {
            display: none;
        }

    }

	&__icon {

        @include transition();
        border-radius: 3px;
        position: relative;
		display: flex;
		flex-shrink: 0;

		width: 1.2rem;
		height: 1.2rem;
		margin-right: .5rem;

		background: #ffffff;
		border: 1px solid #ccd4d8;

        &:after {
            @include transition();
            border-radius: 2px;
            content: "";
            position: absolute;
            width: .6rem;
            height: .6rem;
            top: 50%;
            left: 50%;
            background-color: transparent;
            transform: translate(-50%, -50%);
        }

	}

    &.is-disabled {
		pointer-events: none;

		color: #cdcdcd;
	}

	.is-disabled &__icon {
		background: #f1f1f1;
	}

	input {
		position: absolute;

		clip: rect(0 0 0 0);

		width: 1px;
		height: 1px;
		margin: -1px;

		&:checked {
			& ~ .checkbox__icon:after {
				background-color: $blue;
			}
		}
	}
}

.checkbox.is-disabled input:checked ~ .checkbox__icon:after {
    background-color: $dark-gray !important;
}

.radio {
    @include transition();
	display: flex;
	align-items: center;

	cursor: pointer;

    &:hover {
        color: $blue;
    }

	&:hover &__icon {
		@include above($desktop) {
			@media (hover: hover) {
				border-color: $dark-gray;
			}
		}
	}

	&__icon {

        @include transition();
        border-radius: 100%;
        position: relative;
		display: flex;
		flex-shrink: 0;

		width: 1.2rem;
		height: 1.2rem;
		margin-right: .5rem;

		background: #ffffff;
		border: 1px solid #ccd4d8;

        &:after {
            @include transition();
            border-radius: 100%;
            content: "";
            position: absolute;
            width: .6rem;
            height: .6rem;
            top: calc(50% + 0.01rem);
            left: 50%;
            background-color: transparent;
            transform: translate(-50%, -50%);
        }

	}

    &.is-disabled {
		pointer-events: none;

		color: #cdcdcd;
	}

	.is-disabled &__icon {
		background: #f1f1f1;
	}

	input {
		position: absolute;

		clip: rect(0 0 0 0);

		width: 1px;
		height: 1px;
		margin: -1px;

		&:checked {
			& ~ .radio__icon:after {
				background-color: $blue;
			}
		}
	}
}
