.nav {
    position: relative;
    z-index: 1;
    width: calc(100% - 4rem);
    max-width: 94rem;
    font-size: 0;

    &-item {
        display: inline-block;
        font-size: 1.6rem;
        margin: .35rem 3.5rem .35rem 0;
        color: $base;

        @include below($tablet) {
            font-size: 1rem;
            margin: .25rem 1rem .25rem 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active {
            font-family: $roboto-medium;
            border-color: transparent;

            &:hover {
                color: $base;
            }

        }

    }

    &--white {
        & .nav-item {
            color: $white;
            border-color: $white;
        }
    }

}
