@mixin below($width, $orientation: 'width') {
    @media all and (max-#{$orientation}: $width) {
        @content;
    }
}

@mixin above($width, $orientation: 'width') {
    @media all and (min-#{$orientation}: $width) {
        @content;
    }
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin margin-x($size){
    margin-left: $size;
    margin-right: $size;
}

@mixin padding-x($size){
    padding-left: $size;
    padding-right: $size;
}
@mixin margin-y($size){
    margin-top: $size;
    margin-bottom: $size;
}

@mixin padding-y($size){
    padding-top: $size;
    padding-bottom: $size;
}

@mixin transition() {
    transition: all .25s ease;
}
