.link {
    @include transition();
    display: inline-block;
    color: $base;
    text-decoration: none;
    border-bottom: 2px solid $blue;

    &:hover {
        color: $blue;
    }

}
