$preloderSize: 3rem;

.preloader {
	@include transition();
	position: absolute;
	z-index: 11;
	top: 50%;
	left: 0;

	width: 100%;
	height: 100%;
	max-height: 100%;


	transform: translate(0, -50%);

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: $preloderSize;
		height: $preloderSize;
		margin: auto;
	}
}

.has-preloader-backdrop {
	> *:not(.preloader) {
		@include transition();
		pointer-events: none;
		opacity: .3;
	}
}
