$base: rgba(0, 0, 0, .87);
$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$blue: rgba(31, 126, 189, 1);
$gray: rgba(243, 243, 243, 1);
$dark-gray: rgba(144, 144, 144, 1);
$red: #dc3545;

$roboto-thin: 'Roboto Thin';
$roboto-light: 'Roboto Light';
$roboto-regular: 'Roboto Regular';
$roboto-medium: 'Roboto Medium';

$phone: 360px;
$mobile: 600px;
$tablet: 768px;
$tablet-landscape: 900px;
$desktop: 1110px;
$mid-desktop: 1280px;
$large-desktop: 1440px;
$big-desktop: 1750px;
$extra-desktop: 2000px;
